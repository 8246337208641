import React, { Component } from "react";
import { withRouter } from "react-router";
import { Route, Switch } from "react-router-dom";
import { AppContext } from "../app-context";

import Navigation from "./Navigation";
import ComputerLayout from "./computer/ComputerLayout";
import Language from "./Language";
import NotCreated from "./NotCreated";
import { API, graphqlOperation } from "aws-amplify";


class Main extends Component {
  state = {
    info: {}
  };
  
  getInfo = () => {
    const ListEvents = `query GetUserInfo {
      GetUserInfo (WithComputerInfo: true) {
        Id
        computer {
          id
          name
          storageTotal
          storageUsed
          storageFree
          dtCreated
          state
          updatesPending
          privateIpAddress
          publicIpAddress
          dtLastRun
          cpus
          gpu
          ram
          os
          events {
            name
            state
            errorMsg
            dtFinished
            dtCreated
          }
        }
      }
    }`;
    API.graphql(graphqlOperation(ListEvents)).then(response => {
      this.setState({ info: response });
    })
    .catch(err => {
      console.log(err);
    });
  };
  
  render() {
    const NavigationWithRouter = withRouter(Navigation);

    if (this.state.info.data && !this.state.info.data.GetUserInfo.computer) {
      return <NotCreated />
    } else {
      return (
        <div className="modal-content">
          <div className="modal-header">
            <NavigationWithRouter />
          </div>
          <div className="modal-body">
            <Switch>
              <Route
                path="/computer"
                component={props => <ComputerLayout {...props} info={this.state.info} getInfo={this.getInfo} />}
                />
              <Route path="/lang/:lang" component={Language} />
              <Route
                component={props => {
                  props.history.push("/computer");
                  return null;
                }}
                />
            </Switch>
          </div>
        </div>
      );
    }
  }
}


Main.contextType = AppContext;

export default Main;
