import React, { Component } from "react";
import Amplify from 'aws-amplify';

import { AppContext } from "./app-context";
import Layout from './components/Layout';

import config from "./config.js"

Amplify.configure(config.amplify);

class App extends Component {
  state = {
    status: "",
    secondaryStatus: ""
  }
  
  reportStatus = status => {
    this.setState({ status: status });
  };

  reportSecondaryStatus = status => {
    this.setState({ secondaryStatus: status });
  };

  render() {
    if (this.props.authState === "signedIn") {
      return (
        <AppContext.Provider value={this}>
          <Layout />
        </AppContext.Provider>
      );
    } else {
      return null;
    }
  }
}

export default App;