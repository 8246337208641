import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Container } from "react-bootstrap";

import Main from "./Main";

const Layout = () => (
  <Container fluid style={{ padding: 0 }}>
    <Router>
      <Main />
    </Router>
  </Container>
);

export default Layout;