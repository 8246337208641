import React, { Component } from "react";
import { Spinner } from "../Spinner";
import { streams } from "@paperspace/client-sdk";
import { API, graphqlOperation } from "aws-amplify";

class SafeMode extends Component {

  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    if (!("data" in this.props.info)) {
      this.props.getInfo();
    }

    if (document.readyState !== 'loading') {
      if (this.props.info.data) {
        const accessKey = `mutation {
          CloudComputerAccessKey
        }`;
        API.graphql(graphqlOperation(accessKey)).then(response => {
          var parsed = JSON.parse(response["data"]["CloudComputerAccessKey"]);
          const vmInfo = {
            accessToken: parsed["accessTokenId"],
            machineId: parsed["delegation"]["machine"]["ids"][0],
          };
          streams.createStream(this.myRef.current, vmInfo).then((stream) => {
           
            stream.open();
          });
        })
        .catch(err => {
          console.log(err);
        });
      }
    }
  }

  render() {
    if (!("data" in this.props.info)) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }
    return <div className="iframe-container"><iframe title="safe-mode" ref={this.myRef} allowFullScreen></iframe></div>;
  }
}

export default SafeMode;
