import React, { Component } from "react";
import { Trans } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";
import { Row, Col, ListGroup, Nav } from "react-bootstrap";

import { Spinner } from "../Spinner";
import { ErrorMessage } from "../ErrorMessage";

import restartIcon from "../../assets/restart.svg";
import powerOnIcon from "../../assets/power-on.svg";
import powerOffIcon from "../../assets/power-off.svg";
import leftArrowIcon from "../../assets/left-arrow.svg";


class RemotePower extends Component {
  state = {
    loading: false,
    error: null,
  };

  /**
   * Handle remote power off
   *
   */
  handleRemotePowerOff = () => {
    this.setState({ loading: true });

    const CloudComputerStop = `mutation CloudComputerStop {
      CloudComputerStop
    }`;

    API.graphql(graphqlOperation(CloudComputerStop))
      .then(response => {
        if (!response["data"]["CloudComputerStop"]) {
          throw Error("Error while stopping the machine: " + response.statusText);
        }
        this.setState({ loading: false });
        this.props.history.push("/computer/remote-state/off");
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  /**
   * Handle remote power on
   *
   */
  handleRemotePowerOn = () => {
    this.setState({ loading: true });

    const CloudComputerStart = `mutation CloudComputerStart {
      CloudComputerStart
    }`;

    API.graphql(graphqlOperation(CloudComputerStart))
      .then(response => {
        if (!response["data"]["CloudComputerStart"]) {
          throw Error("Error while starting the machine: " + response.statusText);
        }
        this.setState({ loading: false });
        this.props.history.push("/computer/remote-state/ready");
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  /**
   * Handle remote restart
   *
   */
  handleRemoteRestart = () => {
    this.setState({ loading: true });

    const CloudComputerRestart = `mutation CloudComputerRestart {
      CloudComputerRestart
    }`;

    API.graphql(graphqlOperation(CloudComputerRestart))
      .then(response => {
        if (!response["data"]["CloudComputerRestart"]) {
          throw Error("Error while restarting the machine: " + response.statusText);
        }
        this.setState({ loading: false });
        this.props.history.push("/computer/remote-state/serviceready");
      })
      .catch(err => {
        this.setState({ error: err, loading: false });
      });
  };

  componentDidMount() {
    if (!("data" in this.props.info)) {
      this.props.getInfo();
    } else {
      setTimeout(this.props.getInfo, 5000);
    }
  }

  render() {
    if (this.state.error !== null) {
      return <ErrorMessage message={this.state.error.toString()} />;
    }
    if (this.state.loading) {
      return <Spinner />;
    }
    let lo;

    if((this.props.info.data)) {
      if (this.props.info.data.GetUserInfo.computer.state === "off") {
        lo = (
          <ListGroup.Item action>
            <Nav.Link onClick={this.handleRemotePowerOn}>
              <i
                className="mr-3"
                style={{
                  verticalAlign: "bottom",
                  width: 40,
                  height: 40,
                  backgroundImage: `url(${powerOnIcon})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  display: "inline-block"
                }}
              />
              <label>
                <Trans>LBL_TURN_ON</Trans>
                <small style={{ display: "block", lineHeight: ".7rem" }}>
                  <Trans>SUB_TURN_ON</Trans>
                </small>
              </label>
            </Nav.Link>
          </ListGroup.Item>
        );
      } else if (this.props.info.data.GetUserInfo.computer.state === "ready" || this.props.info.data.GetUserInfo.computer.state === 'serviceready') {
        lo = (
          <React.Fragment>
            <ListGroup.Item action>
              <Nav.Link onClick={this.handleRemotePowerOff}>
                <i
                  className="mr-3"
                  style={{
                    verticalAlign: "bottom",
                    width: 40,
                    height: 40,
                    backgroundImage: `url(${powerOffIcon})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "inline-block"
                  }}
                />
                <label>
                  <Trans>LBL_TURN_OFF</Trans>
                  <small style={{ display: "block", lineHeight: ".7rem" }}>
                    <Trans>SUB_TURN_OFF</Trans>
                  </small>
                </label>
              </Nav.Link>
            </ListGroup.Item>
            <ListGroup.Item action>
              <Nav.Link onClick={this.handleRemoteRestart}>
                <i
                  className="mr-3"
                  style={{
                    verticalAlign: "bottom",
                    width: 40,
                    height: 40,
                    backgroundImage: `url(${restartIcon})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "inline-block"
                  }}
                />
                <label>
                  <Trans>LBL_RESTART</Trans>
                  <small style={{ display: "block", lineHeight: ".7rem" }}>
                    <Trans>SUB_RESTART</Trans>
                  </small>
                </label>
              </Nav.Link>
            </ListGroup.Item>
          </React.Fragment>
        );
      } else {
        lo = (
          <div className="text-center">
            <Spinner />
          </div>
        );
      } 
    } else {
      lo = (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    return (
      <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
        <h4 className="m-3">
          <Trans>TITLE_POWER_MANAGEMENT</Trans>
          <div className="float-right mr-2">
            <i
              onClick={() => this.props.history.push("/computer")}
              style={{
                verticalAlign: "bottom",
                width: 35,
                height: 35,
                backgroundImage: `url(${leftArrowIcon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block"
              }}
            />
          </div>
        </h4>
        <Row className="mt-2">
          <Col>
            <ListGroup variant="flush">{lo}</ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RemotePower;
