import React from 'react';

import Amplify from "aws-amplify";
import { Authenticator } from "aws-amplify-react";
import config from "./config.js";

import App from "./App"
import { I18n } from 'aws-amplify';

Amplify.configure(config.amplify);

const authScreenLabels = {
    en: {
        'Confirm Sign Up': 'Check your email to confirm Sign Up'
    }
};

I18n.setLanguage('en');
I18n.putVocabularies(authScreenLabels);


class AppWithAuth extends React.Component {
  render() {
    return (
      <Authenticator
        usernameAttributes="email"
        signUpConfig={
          {
            hiddenDefaults: ["phone_number"]
          }     
        }
      >
        <App />
      </Authenticator>
    )
  }
}

export default AppWithAuth;
