import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Row, Col, ListGroup, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";

import plugIcon from "../../assets/plug.svg";
import deskIcon from "../../assets/desktop.svg";
import rightArrowIcon from "../../assets/right-arrow.svg";
import RemoteIndicator from "../RemoteIndicator.jsx";

class MyComputer extends Component {
  state = {
    error: null
  };

  componentDidMount() {
    if (!("data" in this.props.info)) {
      this.props.getInfo();
    }
  }

  render() {
    var status = "restarting";
    if(("data" in this.props.info)){
      status = this.props.info.data.GetUserInfo.computer.state;
    }

    return (
      <div style={{ backgroundColor: "#fff" }}>
        <h4 className="m-3">
          <i
            style={{
              verticalAlign: "middle",
              width: 15,
              height: 15,
              backgroundImage: `url(${rightArrowIcon})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              display: "inline-block"
            }}
          />
          <Trans>TITLE_MY_COMPUTER</Trans>
          <RemoteIndicator state={status} />
        </h4>

        <Row className="mt-2">
          <Col>
            <ListGroup variant="flush">
              <ListGroup.Item action>
                <Nav.Link as={Link} to="/computer/remote-power" disabled={!("data" in this.props.info)}>
                  <i
                    className="mr-3"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${plugIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />
                  <label>
                    <Trans>LBL_POWER_MANAGEMENT</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      <Trans>SUB_POWER_MANAGEMENT</Trans>
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>

              <ListGroup.Item action>
                <Nav.Link as={Link} to="/computer/remote-details" disabled={!("data" in this.props.info)}>
                  <i
                    className="mr-3"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${deskIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />
                  <label>
                    <Trans>LBL_SYSTEM_INFORMATION</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      <Trans>SUB_SYSTEM_INFORMATION</Trans>
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>

              <ListGroup.Item action>
              <Nav.Link as={Link} to="/computer/safe-mode" disabled={!("data" in this.props.info)}>
                  <i
                    className="mr-3"
                    style={{
                      verticalAlign: "bottom",
                      width: 40,
                      height: 40,
                      backgroundImage: `url(${deskIcon})`,
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "inline-block"
                    }}
                  />
                  <label>
                    <Trans>LBL_SAFE_MODE</Trans>
                    <small style={{ display: "block", lineHeight: ".7rem" }}>
                      <Trans>SUB_SYSTEM_INFORMATION</Trans>
                    </small>
                  </label>
                </Nav.Link>
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </div>
    );
  }
}

export default MyComputer;
