import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n

  .use(initReactI18next)

  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "en",

    debug: true,

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },

    lng: navigator.language,
    resources: {
      en: {
        translation: {
          HOME_SLOGAN: "The high-performance computer in your pocket",
          BTN_CONNECT: "Connect",
          LBL_POWERED_BY: "Powered by",
          NAV_MY_COMPUTER: "My Computer",
          NAV_LANGUAGE: "Language",
          NAV_NETWORK: "Network Setting",
          NAV_RESTART: "Restart",
          NAV_SHUTDOWN: "Shut down",
          MSG_CONFIRM_RESTART_DEVICE: "Please confirm you want to restart the device.",
          MSG_CONFIRM_SHUTDOWN_DEVICE: "Please confirm you want to turn off the device.",
          BTN_RESTART: "Restart",
          BTN_CANCEL: "Cancel",
          BTN_SHUTDOWN: "Shut Down",
          TITLE_MY_COMPUTER: "My Computer",
          LBL_POWER_MANAGEMENT: "Power Management",
          SUB_POWER_MANAGEMENT: "Turn on and off your remote machine",
          LBL_SYSTEM_INFORMATION: "System Information",
          SUB_SYSTEM_INFORMATION: "Some more technical details",
          LBL_SAFE_MODE: "Safe Mode",
          TITLE_SYSTEM_INFORMATION: "System Information",
          LBL_STATUS: "Status",
          LBL_NAME: "Name",
          LBL_OS: "OS",
          LBL_RAM: "RAM",
          LBL_CPU_CORES: "CPU Cores",
          LBL_GPU: "GPU",
          LBL_STORAGE: "Storage",
          LBL_PUBLIC_IP: "Public IP",
          LBL_PRIVATE_IP: "Private IP",
          LBL_TURN_ON: "Turn On",
          SUB_TURN_ON: "Power on the computer",
          LBL_TURN_OFF: "Turn Off",
          SUB_TURN_OFF: "Shut down the computer",
          LBL_RESTART: "Restart",
          SUB_RESTART: "Reboot the computer",
          TITLE_POWER_MANAGEMENT: "Power Management",
          TITLE_NETWORK_SETTINGS: "Network Settings",
          LBL_WIRED_CONNECTION: "Wired Connection",
          LBL_CONNECTED: "Connected",
          LBL_SECURED: "Secured",
          LBL_OPEN: "Open",
          LBL_WIRELESS_CONNECTION: "Wireless Connection",
          LBL_SPEED_TEST: "Speed Test",
          SUB_SPEED_TEST: "Test your internet connection speed",
          TITLE_SPEED_TEST: "Speed Test",
          LBL_PING: "Ping",
          LBL_DOWNLOAD_SPEED: "Download",
          LBL_UPLOAD_SPEED: "Upload",
          LBL_SERVER: "Server",
          LBL_BYTES_SENT: "Bytes Sent",
          LBL_BYTES_RECEIVED: "Bytes Received",
          BTN_RUN_AGAIN: "Run again",
          LBL_ENTER_NETKEY: "Enter the network security key",
          LBL_CURRENT_NETWORK: "Current network",
          LBL_AVAILABLE_NETWORKS: "Available networks",
          LBL_NO_NETWORKS_AVAILABLE: "No WiFi networks are available",
          TITLE_WIFI_NETWORKS: "Wi-Fi network list",
          TITLE_WIRED_INTERFACE: "Wired network interface",
          LBL_IP_ADDRESS: "IP Address",
          LBL_NETMASK: "Netmask",
          LBL_NET_GATEWAY: "Gateway",
          LBL_MAC_ADDRESS: "MAC Addr",
          LANG_EN_US: "English",
          LANG_DE_AT: "Deutsch",

          TITLE_SETUP_NETWORK: "Setup Network"
        }
      },
      de: {
        translation: {
          HOME_SLOGAN: "Der Hochleistungscomputer für deine Hosentasche",
          BTN_CONNECT: "Anschließen",

          NAV_MY_COMPUTER: "Mein Computer",
          NAV_NETWORK: "Netzwerk",
          NAV_RESTART: "Neustart",
          NAV_SHUTDOWN: "Ausschalten"
        }
      }
    }
  });

//i18n.changeLanguage(navigator.language);

export default i18n;
