import React from "react";

const RemoteIndicator = ({ state }) => {
  const classMap = {
    off: "state-off",
    starting: "state-pending",
    stopping: "state-pending",
    restarting: "state-pending",
    serviceready: "state-pending",
    ready: "state-on",
    upgrading: "state-available",
    provisioning: "state-available"
  };
  return <div className={"stateDot mr-1 " + classMap[state]} />;
};

export default RemoteIndicator;