import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Row, Col, Badge } from "react-bootstrap";

import FormatBytes from "../FormatBytes";
import { Spinner } from "../Spinner";

import leftArrowIcon from "../../assets/left-arrow.svg";

class RemoteDetails extends Component {
  
  componentDidMount () {
    if (!("data" in this.props.info)) {
      this.props.getInfo();
    } else {
      setTimeout(this.props.getInfo, 5000);
    }
  }
  
  render() {
    if (!("data" in this.props.info)) {
      return (
        <div className="text-center">
          <Spinner />
        </div>
      );
    }

    const machineInfo = this.props.info.data.GetUserInfo.computer;
    
    return (
      <div style={{ backgroundColor: "#fff", padding: "1rem" }}>
        <h4>
          <Trans>TITLE_SYSTEM_INFORMATION</Trans>
          <div className="float-right mr-2">
            <i
              onClick={() => this.props.history.push("/computer")}
              style={{
                verticalAlign: "bottom",
                width: 35,
                height: 35,
                backgroundImage: `url(${leftArrowIcon})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                display: "inline-block"
              }}
            />
          </div>
        </h4>

        <Row className="mt-3">
          <Col xs={2}>
            <Trans>LBL_STATUS</Trans>:
          </Col>{" "}
          <Col>
            <h5>
              <Badge variant="dark" style={{ textTransform: "uppercase" }}>
                {machineInfo["state"]}
              </Badge>
            </h5>
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_NAME</Trans>:
          </Col>{" "}
          <Col>{machineInfo["name"]}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_OS</Trans>:
          </Col>{" "}
          <Col>{machineInfo["os"]}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_RAM</Trans>:
          </Col>{" "}
          <Col>{FormatBytes(machineInfo["ram"])}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_CPU_CORES</Trans>:
          </Col>{" "}
          <Col>{machineInfo["cpus"]}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_GPU</Trans>:
          </Col>{" "}
          <Col>{machineInfo["gpu"]}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_STORAGE</Trans>:
          </Col>{" "}
          <Col>
            {FormatBytes(machineInfo["storageUsed"]) + " / " + FormatBytes(machineInfo["storageTotal"])}
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_PUBLIC_IP</Trans>:
          </Col>{" "}
          <Col>{machineInfo["publicIpAddress"]}</Col>
        </Row>
        <Row>
          <Col xs={2}>
            <Trans>LBL_PRIVATE_IP</Trans>:
          </Col>{" "}
          <Col>{machineInfo["privateIpAddress"]}</Col>
        </Row>
      </div>
    );
  }
}

export default RemoteDetails;
