import React, { Component } from "react";
import { Spinner } from "../Spinner";

class RemoteState extends Component {
  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    if ("data" in this.props.info && params.tobe === this.props.info.data.GetUserInfo.computer.state) {
      this.props.history.push("/computer/remote-details");
      return true;
    }

    setTimeout(this.props.getInfo, 5000);
    return true;
  }

  render() {
    return (
      <div className="m-3 text-center">
        <Spinner />
      </div>
    );
  }
}

export default RemoteState;
