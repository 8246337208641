import React from "react";
import { Route, Switch } from "react-router-dom";

import RemotePower from "./RemotePower";
import RemoteDetails from "./RemoteDetails";
import RemoteState from "./RemoteState";
import MyComputer from "./MyComputer";
import SafeMode from "./SafeMode";

const computerLayout = (props) => (
  <Switch>
    <Route path="/computer/remote-power" component={() => <RemotePower {...props} />} />
    <Route path="/computer/remote-details" component={() => <RemoteDetails {...props} />} />
    <Route path="/computer/safe-mode" component={() => <SafeMode {...props} />} />
    <Route path="/computer/remote-state/:tobe" component={params => <RemoteState {...props} {...params} />} />
    <Route component={() => <MyComputer {...props} />} />
  </Switch>
);

export default computerLayout;
