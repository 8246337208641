import React from 'react';

const notCreated = () => (
    <div className='no-machine'>
        <span>Your machine has not been created yet.</span> 
        <br />
        <span>Please contact an administrator.</span>
    </div>
)

export default notCreated;