import React, { Component } from "react";
import { Trans } from "react-i18next";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import i18n from "../i18n";

import logo from "../assets/rouster-logo.svg";
import iconMonitor from "../assets/monitor.svg";

class Navigation extends Component {
  render() {
    let langs = {
      en: {
        param: "en_US.UTF-8",
        label: <Trans>LANG_EN_US</Trans>
      },
      de: {
        param: "de_AT.UTF-8",
        label: <Trans>LANG_DE_AT</Trans>
      }
    };
    let dropDownLangs = [];
    Object.entries(langs).forEach(
      ([k, lang]) =>
        k !== i18n.language.substr(0, 2) &&
        dropDownLangs.push(
          <NavDropdown.Item key={k} as={Link} to={"/lang/" + lang.param}>
            {lang.label}
          </NavDropdown.Item>
        )
    );

   
    return (
      <Navbar expand="lg" variant="dark" className="w-100">
        <Navbar.Brand as={Link} to="/" style={{ paddingTop: 0, marginRight: 20 }}>
          <img src={logo} height="14" alt="Logo" align="middle" />
        </Navbar.Brand>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Item className="mr-1">
              <Nav.Link
                as={Link}
                to="/computer"
                style={{ minWidth: "120px" }}
                className={this.props.location.pathname.startsWith("/computer") ? "active" : ""}
              >
                <i
                  className="mr-1"
                  style={{
                    verticalAlign: "bottom",
                    width: 32,
                    height: 32,
                    backgroundImage: `url(${iconMonitor})`,
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    display: "inline-block"
                  }}
                />
                <Trans>NAV_MY_COMPUTER</Trans>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Navigation;
