import React, { Component } from "react";
import { Spinner } from "./Spinner";
import i18n from "../i18n";

class Language extends Component {
  state = {
    loading: true
  };

  componentDidMount() {
    const {
      match: { params }
    } = this.props;

    const formData = new FormData();
    formData.append("locale", params.lang);

    fetch("//rouster/settings/locale", {
      method: "POST",
      body: formData
    });
    i18n.changeLanguage(params.lang.substring(0, 2));
    this.props.history.push("/");
  }

  render() {
    return <Spinner />;
  }
}

export default Language;
